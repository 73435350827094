import React from 'react';
import MainScreenLayout from '../../../components/MainScreenLayout/MainScreenLayout';
import Button from '../../../components/Button/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.scss';

const Index = () => {
  const navigate = useNavigate();

  return (
    <MainScreenLayout>
      <div className='payment-success-wrapper'>
        <h5> Payment Successful. Thanks for the payment. </h5>
        You can check your current plan by going to the membership page.
        <div className='check-your-plan-wrapper'>
          <Button
            title='Check your plan'
            onClick={() => navigate('/membership')}
          />
        </div>
      </div>
    </MainScreenLayout>
  );
};

export default Index;
