import React from 'react';
import Header from '../Header/Header';
import './MainScreenLayout.scss';

const MainScreenLayout = ({ children }: any) => {
  return (
    <div className='main_layout_container'>
      <Header />
      <div className='children_wrapper'>{children}</div>
    </div>
  );
};

export default MainScreenLayout;
