import React from 'react';
import { Button } from '@mui/material';
import './Button.scss';

type Props = {
  variant?: 'Primary' | 'Secondary';
  title: string;
  className?: string;
  onClick?: any;
  isLoading?: boolean;
  disabled?: boolean;
};

const AFButton = ({
  title,
  className,
  onClick,
  isLoading,
  disabled,
}: Props) => {
  return (
    <>
      <Button
        variant='contained'
        className={`primary-btn ${className}`}
        disabled={disabled}
        onClick={onClick}
      >
        {isLoading ? 'Loading...' : title}
      </Button>
    </>
  );
};

export default AFButton;
