import React, { useEffect, useState } from 'react';
import MainScreenLayout from '../../../components/MainScreenLayout/MainScreenLayout';
import './index.scss';
import AFButton from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import SomethingWentWrong from '../../SomethingWentWrong/SomethingWentWrong';
import { membershipUrl } from '../../../Services/APIConstant';
import axios from 'axios';

const Index = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const [currentPlan, setCurrentPlan] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const navigate = useNavigate();
  const token = localStorage.getItem('aageToken');

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        setIsLoading(true);
        const response: any = await axios.get(
          `${membershipUrl}/current_membership?token=${token}`
        );
        if (response.data.success === true) {
          setUserId(response.data.user_id);
          setCurrentPlan(response.data.plan_name);
          setStartDate(response.data.start_date);
          setEndDate(response.data.end_date);
        } else if (response.data.success === false) {
        } else if (response.status === 500) {
          <SomethingWentWrong err={response.data.message} />;
        } else if (response.status === 429) {
          <SomethingWentWrong err={response.data.message} />;
        }
      } catch (err) {
        console.log('error', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPricingPlans();
  }, []);

  const handleRenew = () => {
    navigate('/pricing-plans');
  };
  return (
    <MainScreenLayout>
      <div className='membership_details_container'>
        <h2>Current Membership Details</h2>
        <div className='details_wrapper'>
          <div className='card_container'>
            User ID: <p>{userId}</p>
          </div>
          <div className='card_container'>
            Current Plan: <p>{currentPlan}</p>
          </div>
          <div className='card_container'>
            Start Date: <p>{startDate}</p>
          </div>
          <div className='card_container'>
            End Date: <p>{endDate}</p>
          </div>
        </div>
      </div>
      <div className='renew-btn-wrapper'>
        <AFButton title='Renew/Upgrade Plan' onClick={handleRenew} />
      </div>
    </MainScreenLayout>
  );
};

export default Index;
