import React from 'react';
import auto_forward_bot_icon from '../../assets/Icons/auto_forwarder_bot_logo_icon.jpg';
import './Header.scss';

const Header = () => {
  return (
    <div className='header_container'>
      <div className='icon_heading_wrapper'>
        <img src={auto_forward_bot_icon} alt='aoto_forward' />
        <h2>Auto Forwarder Bot</h2>
      </div>
    </div>
  );
};

export default Header;
